.shadowku {
  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

ul.vector-menu-content-list {
  display: flex;
  flex-wrap: wrap;
}

li.interlanguage-link {
  flex: 0 0 20%;
  box-sizing: border-box;
  padding: 5px;
}

#mw-hidden-catlinks ul {
  display: flex;
  flex-wrap: wrap;
}

#mw-hidden-catlinks li {
  flex: 0 0 33%;
  box-sizing: border-box;
  margin: 5px;
}

.NavContent ul {
  display: flex;
  flex-wrap: wrap;
}

.NavContent li {
  flex: 0 0 33%;
  box-sizing: border-box;
  margin: 10px;
}
@media screen and (max-width: 600px) {
  img {
    width: 100%;
    height: auto;
  }

  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
* aria-label has no advantage, it won't be read inside a table
content: attr(aria-label);
*/
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

#popup {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.popup {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 601px) {
  .popup-content {
    width: 500px;
  }
}

@media (min-width: 451px) and (max-width: 600px) {
  .popup-content {
    width: 400px;
  }
}

@media (min-width: 354px) and (max-width: 450px) {
  .popup-content {
    width: 300px;
  }
}

@media (max-width: 353px) {
  .popup-content {
    max-width: 250px;
  }
}

.popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  /* border:1px  */
}

.close {
  position: absolute;
  top: -20px;
  right: -20px;
  padding-left: 7px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  font-size: 35px;
  width: 30px;
  height: 30px;
  color: red !important;
  background-color: #fff !important;
}
